<template>
  <div>
    <div class="display-flex-sb-t display-flex-align-center header-caption">
      <div>{{ $t("PROBABILITY_OF_HOME_&_AWAY") }}</div>
      <div class="recent-match-wrapper" v-if="!isShowNoData && !isLoading">
        <MatchListDropdown
          :lists="recentMatchSelectList"
          :lengthSelected="selectedRecentMatches"
          prefixCaption="RECENT"
          postfixCaption="GAMES"
          :leftMargin="true"
          :rightMargin="false"
          @lengthHandler="updateSelectedList"
        />
      </div>
    </div>

    <div class="no-data" v-show="isShowNoData && !isLoading">
      {{ $t("NO_DATA") }}
    </div>

    <table
      class="table-content"
      cellpadding="0"
      cellspacing="0"
      v-if="!isShowNoData && !isLoading"
    >
      <tr>
        <td class="text-bold-grey-center table-header-top-row" colspan="5">
          {{ $t("HOME") }} / {{ $t("AWAY") }}
        </td>
      </tr>
      <tr class="five-column-row">
        <td class="text-bold-grey-center">{{ $t("GOAL") }}</td>
        <td class="text-bold-grey-center">{{ $t("CONCEDED") }}</td>
        <td class="text-bold-grey-center">{{ $t("PERIOD") }}</td>
        <td class="text-bold-grey-center">{{ $t("GOAL") }}</td>
        <td class="text-bold-grey-center">{{ $t("CONCEDED") }}</td>
      </tr>
      <tr
        class="five-column-row table-row-data"
        v-for="list in selectedPastList"
        :key="list"
      >
        <td class="text-centered">{{ list.homeGoalRate }}%</td>
        <td class="text-centered">{{ list.homeLoseRate }}%</td>
        <td class="text-centered text-bold-grey-center">{{ list.period }}</td>
        <td class="text-centered">{{ list.awayGoalRate }}%</td>
        <td class="text-centered">{{ list.awayLoseRate }}%</td>
      </tr>
    </table>
  </div>
  <Loading v-show="isLoading"></Loading>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MatchListDropdown from "@/components/ui/MatchListDropdown.vue";

export default {
  props: {
    matchId: {
      type: Number,
    },
  },
  components: { MatchListDropdown },
  mounted() {
    this.getPastGoalRateData();
  },
  computed: {
    ...mapGetters(["isMobile"]),
    selectedPastList() {
      return this.pastLists["past" + this.selectedRecentMatches + "List"];
    },
  },
  data() {
    return {
      isLoading: false,
      isShowNoData: false,
      isShowRecentMatchSelectList: false,
      pastLists: {},
      recentMatchSelectList: [],
      selectedRecentMatches: "",
    };
  },
  methods: {
    ...mapActions(["getPastGoalRate"]),
    async getPastGoalRateData() {
      this.isLoading = true;
      let params = {
        matchId: this.matchId,
      };
      const result = await this.getPastGoalRate(params);
      this.pastLists = result.result;
      let emptyResult = true;

      for (const [key, value] of Object.entries(this.pastLists)) {
        let recentMatchNo = key.replace(/past|List/g, "");

        // set if not empty result, set period interval (e.g. 1-15)
        if (value.length > 0) {
          let lastEventEnded, tempLastEventEnded;
          emptyResult = false;
          this.pastLists[key].forEach((data, i) => {
            if (i === 0) {
              lastEventEnded = data.period;
              data.period = "1-" + data.period;
            } else {
              tempLastEventEnded = data.period;
              data.period = lastEventEnded + 1 + "-" + data.period;
              lastEventEnded = tempLastEventEnded;
            }
          });
          this.recentMatchSelectList.push(recentMatchNo);
        }
      }

      if (this.recentMatchSelectList.length > 0) {
        this.selectedRecentMatches = this.recentMatchSelectList[0];
      }

      if (emptyResult) {
        this.isShowNoData = true;
      } else {
        this.isShowNoData = false;
      }

      this.isLoading = false;
    },
    updateSelectedList(data) {
      this.selectedRecentMatches = data;
    },
  },
};
</script>

<style scoped>
.header-caption {
  font-size: 0.75rem;
  margin-bottom: 0.563rem;
}
.recent-match-wrapper .dropdown {
  font-size: 0.75rem;
}
.table-content {
  width: 100%;
  font-size: 0.688rem;
}
.table-header-top-row {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.text-bold-grey-center {
  background: var(--color-grey-60);
  font-weight: 700;
  text-align: center;
}
.text-centered {
  text-align: center;
}
.table-content td {
  padding: 0.375rem 0;
  border-bottom: 1px solid var(--color-grey-40);
}
.table-row-data td:first-of-type {
  border-left: 1px solid var(--color-grey-40);
}
.table-row-data td:last-of-type {
  border-right: 1px solid var(--color-grey-40);
}
.five-column-row td {
  width: calc(100% / 5);
  box-sizing: border-box;
}
</style>
