<template>
  <div
    v-if="isShow"
    class="modal-wrapper darker  display-flex-center flex-col"
  >
    <transition name="slideUp">
        <div class="w-90"  v-if="isShowContent">
            <div class="modal-header">
                <div class="pointer" @click="handleClose">
                    <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path
                        d="M23.6663 2.68301L21.3163 0.333008L11.9997 9.64968L2.68301 0.333008L0.333008 2.68301L9.64968 11.9997L0.333008 21.3163L2.68301 23.6663L11.9997 14.3497L21.3163 23.6663L23.6663 21.3163L14.3497 11.9997L23.6663 2.68301Z"
                        fill="white"
                    />
                    </svg>
                </div>
            </div>
            <div>
                <slot></slot>
            </div>
            <!-- <div
                class="modal-container"
                @click="handleContent($event)"
                v-if="isShowContent"
                :class="[{ 'modal-container__fullscreen': isFullScreen }, modalSize]"
            >
        
                <div class="modal-bodys">
            
                </div>
            </div> -->
        </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    //options : large
    modalSize: {
      type: String,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      isShowContent: false,
    };
  },
  created() {},
  beforeUnmount() {
    document.body.classList.remove("hidden");
  },
  mounted() {
    window.addEventListener("keydown", this.listenKeypressEvent);
  },
  methods: {
    handleOpen() {
      document.body.className = "hidden";
      this.showModal();
    },
    handleClose() {
      this.$emit("handleCloseEvent");
      document.body.classList.remove("hidden");
      this.hideModal();
    },
    showModal() {
      this.isShow = true;
      setTimeout(() => (this.isShowContent = true), 500);
    },
    hideModal() {
      this.isShowContent = false;
      setTimeout(() => (this.isShow = false), 500);
    },
    listenKeypressEvent(e) {
      if (e.key === "Escape") {
        this.handleClose();
      }
    },
    handleContent(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped>

</style>
