<template>
  <div v-if="isMobile">
    <table class="odds-detail__table-mobile">
      <thead>
        <th colspan="3" class="odds-detail__table-header__mobile">
          {{this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet"}}
        </th>
      </thead>
      <tbody>
        <template v-for="(level0, index0) in oddsMatchList" :key="index0">
          <tr>
            <th></th>
            <th> {{ $t(index0 === 0 ? "FULL_TIME_ODDS" : "FIRST_HALF_ODDS") }}</th>
          </tr>
          <tr>
            <td class="odds-detail__table-score__mobile"></td>
            <td class="odds-detail__table-score__mobile">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">HDP</span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">O/U</span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">1X2</span>
                </div>
            </td>
          </tr>
          <tr>
            <td class=" odds-detail__table-score__mobile" :class="preDataColorFilter('handicap','home','pre', index0)">{{ oddsMatchData.data.opponents[0].name }}</td>
            <td class="odds-detail__table-score__mobile">
                <div class="odds-detail__table-score__mobile-child">
                  <span  class="odds-detail__table-score__mobile-pre">{{ preDataFilter("handicap","home","pre", index0) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("handicap","home", "val", index0) }}</span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre">{{ preDataFilter("overUnder","home", "pre", index0) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("overUnder","home", "val", index0) }}</span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","home", "val", index0) }}</span>
                </div>
            </td>
          </tr>
          <tr>
            <td class="odds-detail__table-score__mobile" :class="preDataColorFilter('handicap','away','pre', index0)">{{ oddsMatchData.data.opponents[1].name }}</td>
            <td class="odds-detail__table-score__mobile">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre">{{ preDataFilter("handicap","away", "pre", index0) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("handicap","away", "val", index0) }}</span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre">u</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("overUnder","away", "val", index0) }}</span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","away", "val", index0) }}</span>
                </div>
            </td>
          </tr>
          <tr>
            <td class="odds-detail__table-score__mobile">{{ $t('DRAW') }}</td>
            <td class="odds-detail__table-score__mobile">
                <div class="odds-detail__table-score__mobile-child">
                  <span  class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val"></span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val"></span>
                </div>
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","away", "draw", index0) }}</span>
                </div>
            </td>
          </tr>
          <!-- <tr>
            <th></th>
            <th>
              {{ $t(index0 === 0 ? "LATEST_INDEX_ALL" : "LATEST_INDEX_HALF") }}
            </th>
            <th>
              {{
                $t(index0 === 0 ? "INITIAL_INDEX_ALL" : "INITIAL_INDEX_HALF")
              }}
            </th>
          </tr>
          <tr v-for="(level1, index1) in level0.data" :key="index1">
            <td class="bold">{{ $t(level1.parentDisplayName) }}</td>
            <td
              class="odds-detail__table-score__mobile"
              v-for="(level2, index2) in level1.child"
              :key="index2"
            >
              <span
                v-for="(level3, index3) in oddMatchDataLength"
                :key="index3"
                :class="dataColorFilter(level0, level1, level2, index3)"
              >
                {{ dataFilter(level0, level1, level2, index3) }}
              </span>
            </td>
          </tr> -->
        </template>
      </tbody>
      <tfoot>
        <tr class="odds-detail__footer">
          <td colspan="3"></td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div v-else class="home-match__static-detail__wrapper">
    <div class="home-match__static-detail__indicator-wrapper">
      <img
        class="home-match__static-detail__indicator-img"
        src="../../../static/images/tooltips_triangle_top_bg_color_1.png"
      />
    </div>
    <div class="home-match__static-detail__body">
      <div class="home-match__static-detail__body-header">
        {{this.currentCountryObj?.countryCode === "TH" ? "SBOBET" : "IBCbet"}}
      </div>
        <!-- <template v-for="(level0, index0) in oddsMatchList" :key="index0">
       
        </template> -->
       
      <div class="home-match__static-detail__container">
        <div class="home-match__static-detail__card-header">
          <div class="home-match__static-detail__score-title"></div>
          <div class="home-match__static-detail__score-wrapper">
            {{ $t('FULL_TIME_ODDS') }}
          </div>
          <div class="home-match__static-detail__score-wrapper">
            {{ $t('FIRST_HALF_ODDS') }}
          </div>
        </div>
        <div class="home-match__static-detail__card">
         <div class="home-match__static-detail__score-title"></div>
          <div class="home-match__static-detail__score-wrapper">
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">HDP</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">O/U</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">1X2</span>
                </div>
            </div>
          </div>
           <div class="home-match__static-detail__score-wrapper">
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">HDP</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">O/U</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">1X2</span>
                </div>
            </div>
          </div>
        </div>
        <div class="home-match__static-detail__card">
          <div class="home-match__static-detail__score-title"  :class="preDataColorFilter('handicap','home','pre', 0)">  
            <div class="icon-league home-match__icon-team"  ><img :src="`${s3ImgUrl}/${oddsMatchData.data.opponents[0].logo}`" /> </div>           
           {{ oddsMatchData.data.opponents[0].name }}</div>
          <div class="home-match__static-detail__score-wrapper">
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span  class="odds-detail__table-score__mobile-pre">{{ preDataFilter("handicap","home","pre", 0) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("handicap","home", "val", 0) }}</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">   
              <div class="odds-detail__table-score__mobile-child">
                <span class="odds-detail__table-score__mobile-pre">{{ preDataFilter("overUnder","home", "pre", 0) }}</span>
                <span class="odds-detail__table-score__mobile-val">{{ dataFilter("overUnder","home", "val", 0) }}</span>
              </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","home", "val", 0) }}</span>
                </div>
            </div>
          </div>
           <div class="home-match__static-detail__score-wrapper">
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span  class="odds-detail__table-score__mobile-pre">{{ preDataFilter("handicap","home","pre", 1) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("handicap","home", "val", 1) }}</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">   
              <div class="odds-detail__table-score__mobile-child">
                <span class="odds-detail__table-score__mobile-pre">{{ preDataFilter("overUnder","home", "pre", 1) }}</span>
                <span class="odds-detail__table-score__mobile-val">{{ dataFilter("overUnder","home", "val", 1) }}</span>
              </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","home", "val", 1) }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="home-match__static-detail__card">
          <div class="home-match__static-detail__score-title" :class="preDataColorFilter('handicap','away','pre', 0)">
            <div class="icon-league home-match__icon-team"><img :src="`${s3ImgUrl}/${oddsMatchData.data.opponents[1].logo}`" /> </div>           
            {{ oddsMatchData.data.opponents[1].name }}</div>
          <div class="home-match__static-detail__score-wrapper">
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span  class="odds-detail__table-score__mobile-pre">{{ preDataFilter("handicap","away","pre", 0) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("handicap","away", "val", 0) }}</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">   
              <div class="odds-detail__table-score__mobile-child">
                <span class="odds-detail__table-score__mobile-pre">u</span>
                <span class="odds-detail__table-score__mobile-val">{{ dataFilter("overUnder","away", "val", 0) }}</span>
              </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","away", "val", 0) }}</span>
                </div>
            </div>
          </div>
           <div class="home-match__static-detail__score-wrapper">
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span  class="odds-detail__table-score__mobile-pre">{{ preDataFilter("handicap","away","pre", 1) }}</span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("handicap","away", "val", 1) }}</span>
                </div>
            </div>
            <div class="home-match__static-detail__score">   
              <div class="odds-detail__table-score__mobile-child">
                <span class="odds-detail__table-score__mobile-pre">u</span>
                <span class="odds-detail__table-score__mobile-val">{{ dataFilter("overUnder","away", "val", 1) }}</span>
              </div>
            </div>
            <div class="home-match__static-detail__score">
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","away", "val", 1) }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="home-match__static-detail__card">
          <div class="home-match__static-detail__score-title">{{ $t('DRAW') }}</div>
            <div class="home-match__static-detail__score-wrapper">
              <div class="home-match__static-detail__score">
                  <div class="odds-detail__table-score__mobile-child">
                    <span  class="odds-detail__table-score__mobile-pre"></span>
                    <span class="odds-detail__table-score__mobile-val"></span>
                  </div>
              </div>
              <div class="home-match__static-detail__score">   
                <div class="odds-detail__table-score__mobile-child">
                  <span class="odds-detail__table-score__mobile-pre"></span>
                  <span class="odds-detail__table-score__mobile-val"></span>
                </div>
              </div>
              <div class="home-match__static-detail__score">
                  <div class="odds-detail__table-score__mobile-child">
                    <span class="odds-detail__table-score__mobile-pre"></span>
                    <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","away", "draw", 0) }}</span>
                  </div>
              </div>
            </div>
           <div class="home-match__static-detail__score-wrapper">
              <div class="home-match__static-detail__score-wrapper">
                <div class="home-match__static-detail__score">
                    <div class="odds-detail__table-score__mobile-child">
                      <span  class="odds-detail__table-score__mobile-pre"></span>
                      <span class="odds-detail__table-score__mobile-val"></span>
                    </div>
                </div>
                <div class="home-match__static-detail__score">   
                  <div class="odds-detail__table-score__mobile-child">
                    <span class="odds-detail__table-score__mobile-pre"></span>
                    <span class="odds-detail__table-score__mobile-val"></span>
                  </div>
                </div>
                <div class="home-match__static-detail__score">
                    <div class="odds-detail__table-score__mobile-child">
                      <span class="odds-detail__table-score__mobile-pre"></span>
                      <span class="odds-detail__table-score__mobile-val">{{ dataFilter("europeOdds","away", "draw", 1) }}</span>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <!-- <div
          class="home-match__static-detail__card"
          v-for="(level1, index1) in level0.data"
          :key="index1"
        >
          <div class="home-match__static-detail__score-title">
            {{ $t(level1.parentDisplayName) }}
          </div>
          <div
            class="home-match__static-detail__score-wrapper"
            :class="level2.status"
            v-for="(level2, index2) in level1.child"
            :key="index2"
          >
            <div
              class="home-match__static-detail__score"
              :class="dataColorFilter(level0, level1, level2, index3)"
              v-for="(level3, index3) in oddMatchDataLength"
              :key="index3"
            >
              {{ dataFilter(level0, level1, level2, index3) }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
let oddsStatus = [
  {
    status: "latest",
  },
  {
    status: "initial",
  },
];

let oddsMatchListObj = [
  {
    parentTitle: "亚走势",
    parentName: "handicap",
    parentDisplayName: "HANDICAP_ODDS",
    child: oddsStatus,
  },
  {
    parentTitle: "欧走势",
    parentName: "europeOdds",
    parentDisplayName: "EUROPE_ODDS",
    child: oddsStatus,
  },
  {
    parentTitle: "大走势",
    parentName: "overUnder",
    parentDisplayName: "OVER_UNDER_ODDS",
    child: oddsStatus,
  },
];
import { mapGetters, mapActions } from "vuex";
import config from "@/js/config.js";

export default {
  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      defaultDataDisplay: "-",
      oddMatchDataLength: 3,
      oddsMatchList: [
        {
          title1: "即时指数 (全场)",
          title2: "初 (全场)",
          field: "All",
          data: oddsMatchListObj,
        },
        {
          title1: "即时指数 (上半场)",
          title2: "初 (上半场)",
          field: "Half",
          data: oddsMatchListObj,
        },
      ],
    };
  },

  props: {
    oddsMatchData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["isMobile", "currentCountryObj"]),
  },

  methods: {
    preDataFilter(type, team, preVal, index) {
      const data = this.oddsMatchData.detailList;

      let result = "-"
      if (type === "overUnder") {
        if (index === 1) {
          type = "overUnderHalf";
        }
        if (data[type]["latest"]) {
          result = data[type]["latest"]["odds1Value"]
        } else {
          result = this.defaultDataDisplay
        }
     
      } else if (type === "handicap") {
        if (index === 1 ) {
          type = "handicapHalf"
        }
        if (data[type]["latest"]) {
          result = data[type]["latest"]["odds1Value"];
        
          if (result === 0) {
            result = result;
          } else {
            if (team === "home") {
              if (result.toString().includes("-")) {
                result = result.toString().replace("-", "+")
              } else {
                result = "-"+result
              }
            } else {
              if (!result.toString().includes("-")) {
                result = "+"+result
              }
            }
          }
        } else {
          result = this.defaultDataDisplay
        }
    
      }
        return result;
    },
    preDataColorFilter(type, team, preVal, index) {
      const defaultColor = "odds-detail__title-colored";
      const data = this.oddsMatchData.detailList;

      let color;

      if (index === 1) {
        type = type+"Half";
      }
      if (data[type]["latest"]) {
        let result = data[type]["latest"]["odds1Value"];
        if (result === 0) {// if data = 0 check HDP value
          let homeVal = data[type]["latest"]["odds2Value"];
          let awayVal = data[type]["latest"]["odds3Value"];

          if (homeVal === awayVal) {
            if (team === "home"){
              color = defaultColor
            }
          } else {
            if (team === "home") {
              if (homeVal < awayVal) {
                color = defaultColor
              }
            }
            if (team === "away") {
              if (awayVal < homeVal) {
                color = defaultColor
              }
            }
          }
        } else {
          if (team === "home") {
            if (result.toString().includes("-")) {
              result = result.toString().replace("-", "+")
            } else {
              result = "-"+result
            }
          }
          if (result.toString().includes("-")) {
            color = defaultColor
          }
        }
      } else {
        debugger
      }
      return color
    },
    dataFilter(type, team, preVal, index) {
      
      const data = this.oddsMatchData.detailList;
      let oddsValue = "odds2Value";

      let result = this.defaultDataDisplay;

      if (index === 1) {
        type = type+"Half";
      }

      if (team === "away") {
        oddsValue = "odds3Value"
      }

      if (type === "europeOdds" || type === "europeOddsHalf") {
        if (preVal === "draw") {
          oddsValue = "odds2Value"
        } else {
          if (team === "home") {
            oddsValue = "odds1Value"
          } else if(team === "away") {
            oddsValue = "odds3Value"
          } 
        }
      }

      if (data[type]["latest"]) {
        result = data[type]["latest"][oddsValue];
      } 
     
      
      return result;
    },
    dataFilter2(level0, level1, level2, index) {
      let result;
      let oddsName;

      let data = this.oddsMatchData.detailList;
      let fieldName = level1.parentName;

      if (level0.field === "Half") {
        fieldName = level1.parentName + level0.field;
      }

      oddsName = data[fieldName][level2.status];

      if (oddsName) {
        //only europeOdds no need switch first and second position
        if (level1.parentName !== "europeOdds") {
          if (index === 0) {
            index = 1;
          } else if (index === 1) {
            index = 0;
          }
        }

        result = oddsName[`odds${index + 1}Value`];
        return result ? result : result === 0 ? "0" : this.defaultDataDisplay;
      } else {
        return this.defaultDataDisplay;
      }
    },
    dataColorFilter(level0, level1, level2, index) {
      let result;
      let oddsName;

      let data = this.oddsMatchData.detailList;
      let fieldName = level1.parentName;

      if (level0.field === "Half") {
        fieldName = level1.parentName + level0.field;
      }

      oddsName = data[fieldName][level2.status];

      if (oddsName) {
        if (level1.parentName !== "europeOdds") {
          if (index === 0) {
            index = 1;
          } else if (index === 1) {
            index = 0;
          }
        }

        result = oddsName[`odds${index + 1}Trend`];
        return `odds-${result}`;
      }
    },
  },
};
</script>

<style>
.home-match__static-detail__wrapper {
  position: absolute;
  top: 3.4rem;
  right: 0;
  z-index: 10;
  width: 592px;
}

.home-match__static-detail__body {
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 1px 3px 6px 3px rgb(34 95 153);
}
.home-match__static-detail__body-header {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  background-color: #212b30;
}
.home-match__static-detail__card {
  display: flex;
  padding: 0.5rem 0;
}
/* .home-match__static-detail__card:hover{
        background-color: var(--bg-hover);
    } */
.home-match__static-detail__score {
  margin: 0 0.25rem;
  font-size: 0.6875rem;
  white-space: nowrap;
  text-align: center;
  width: 3rem;
}
.home-match__static-detail__score-title {
  width: 10rem;
  font-size: 0.6875rem;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}
.home-match__static-detail__indicator-wrapper {
  position: relative;
  height: 0.7rem;
}
.home-match__static-detail__indicator-img {
  position: absolute;
  right: 1.5rem;
}
.home-match__static-detail__container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-theme);
}
.home-match__static-detail__score-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
}
.home-match__static-detail__score-wrapper.latest {
  margin-right: 4rem;
}
.home-match__static-detail__score-wrapper.initial {
  margin-right: 1rem;
}
.home-match__static-detail__card-header {
  display: flex;
  background-color: var(--bg-hover);
  padding: 0.5rem 0;
  font-size: 0.875rem;
}
/* mobile */
.odds-detail__table-mobile {
  width: 100%;
  background-color: #35454d;
  border-radius: 0.5rem;
  border-collapse: collapse;
  overflow: hidden;
}
.odds-detail__table-mobile thead th {
  padding: 0.8125rem;
  border: 0.1rem solid #4b616c;
}
.odds-detail__table-mobile td {
  border: 0.1rem solid #4b616c;
  padding: 0.3125rem 0.25rem;
  font-size: 0.6875rem;
  text-align: center;
  box-sizing: border-box;
}
.odds-detail__table-mobile tbody th {
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.3125rem 0.5rem;
  border: 0.5px solid #4b616c;
}
.odds-detail__table-score__mobile {
  background-color: #212b30;
  width: 50%;
}
.odds-detail__table-score__mobile span {
  /* width: calc(100% / 3); */
  display: inline-block;
}
.odds-detail__footer {
  height: 1rem;
}

.odds-detail__table-score__mobile-child{
    display: inline-block;
    /* width: 33%; */
}
.odds-detail__table-score__mobile-pre{
  margin-right: 0.325rem;
  color: var(--bg-selected);
  min-width: 2rem;
  text-align: right;
  display: inline-block
}
.odds-detail__table-score__mobile-val{
  /* min-width: 1rem; */
}
.odds-detail-blue{
    color: var(--bg-selected);
}
.odds-detail__title-colored{
  color: var(--bg-selected)
}
@media(max-width: 768px) {
  .odds-detail__table-score__mobile-pre{
     min-width: 1.5rem;
     display: block;
     font-size: .6rem;
  }
  .odds-detail__table-score__mobile-val{
    min-width: 1.5rem;
  }
}
</style>
