<template>
  <div class="relative dropdown">
    <div class="display-flex display-flex-center" :class="{ 'mr-1rem': rightMargin, 'ml-1rem': leftMargin }">
      <span class="mr-05rem" v-if="prefix && prefixCaption === ''">{{ $t("LATEST") }}</span>
      <span class="mr-05rem" v-else-if="prefix && prefixCaption !== ''">{{ $t(prefixCaption) }}</span>
      <div class="nav-pill" @click="matchLengthHandler">
        <span class="mr-03rem" v-if="multiLang">{{ lengthSelected }}</span>
        <span class="mr-03rem" v-else>{{ lengthSelected }}</span>
        <i class="svg-arrow__vertical ">
          <svg
            width="13"
            height="8"
            viewBox="0 0 13 8"
            class="league-dropdown__svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z"
              fill="inherit"
            />
          </svg>
        </i>
      </div>
      <span v-if="postfix && postfixCaption === ''">{{ $t("MATCHES") }}</span>
      <span v-if="postfix && postfixCaption !== ''">{{ $t(postfixCaption) }}</span>
    </div>
    <div class="dropdown-wrapper" :class="{ selected: lengthShow }">
      <div class="ps ps--active-x ps--active-y">
        <div class="dropdown-container__wrapper">
          <div
            class="dropdown-container"
            v-for="l in lists"
            :key="l"
            @click="
              $emit('lengthHandler', l);
              matchLengthHandler();
            "
          >
            <div class="dropdown-container__name">
              <span v-if="multiLang">{{ $t(l.toUpperCase()) }}</span>
              <span v-else>{{ l }}</span>
            </div>
            <div
              class="dropdown-container__tick"
              :class="{ selected: lengthSelected === $t(l.toUpperCase()) }"
              v-if="multiLang"
            ></div>
            <div
              class="dropdown-container__tick"
              :class="{ selected: lengthSelected === l }"
              v-else
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lists: {
      type: Array,
    },
    lengthSelected: {
      type: String,
    },
    prefix: {
      type: Boolean,
      default: true,
    },
    postfix: {
      type: Boolean,
      default: true,
    },
    prefixCaption: {
      type: String,
      default: "",
    },
    postfixCaption: {
      type: String,
      default: "",
    },
    multiLang: {
      type: Boolean,
      default: false,
    },
    leftMargin: {
      type: Boolean,
      default: true,
    },
    rightMargin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      lengthShow: false,
    };
  },
  methods: {
    matchLengthHandler() {
      this.lengthShow = !this.lengthShow;
    },
  },
};
</script>

<style scoped>
.league-dropdown__svg {
  fill: white;
}
.dropdown-wrapper {
  left: calc(50% - 3.75rem);
  min-width: 5rem;
  max-height: 10.5rem;
  overflow-y: auto;
}
.dropdown-wrapper::-webkit-scrollbar {
  width: 0.25rem;
}
.dropdown-wrapper::-webkit-scrollbar-track {
  background: none;
}

.dropdown-wrapper::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 0.5rem;
}
.dropdown-container__wrapper {
  max-height: unset;
}
/* .nav-pill {
  min-width: 35px;
} */
.dropdown {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.dropdown .mr-1rem:nth-last-child(1) {
  margin-right: 0;
}
</style>
