<template>
  <div>
    <div class="display-flex-sb-t display-flex-align-center header-caption">
      <div>{{ $t("LIVE_INDEX") }}</div>
      <div class="recent-match-wrapper" v-if="!isShowNoData && !isLoading">
        <MatchListDropdown
          :lists="oddsSelectOptions"
          :lengthSelected="selectedFilterOptionMultiLang"
          :prefix="false"
          :postfix="false"
          :multiLang="true"
          :leftMargin="true"
          :rightMargin="false"
          @lengthHandler="updateSelectedOddList"
        />
      </div>
    </div>
  </div>

  <div class="no-data" v-show="isShowNoData && !isLoading">
    {{ $t("NO_DATA") }}
  </div>

  <table
    class="table-content"
    cellpadding="0"
    cellspacing="0"
    v-if="!isShowNoData && !isLoading"
  >
    <tr class="six-column-row">
      <td class="text-bold-grey-center table-header-top-left-row">
        {{ $t("TIME") }}
      </td>
      <td class="text-bold-grey-center">{{ $t("SCORE") }}</td>
      <td class="text-bold-grey-center table-header-top-right-row" colspan="4">
        {{ $t("INDEX") }}
      </td>
    </tr>
    <template v-for="list in selectedlatest3OddsLists" :key="list">
      <tr class="table-row-data six-column-row">
        <td class="text-centered" rowspan="2">{{ list.period }}</td>
        <td class="text-centered" rowspan="2">
          {{ list.homeScore }}:{{ list.awayScore }}
        </td>
        <td class="text-centered left-border">{{ $t("OPEN_SHORT_FORM") }}</td>
        <td class="text-centered" v-for="odd in list.initOdds" :key="odd">
          {{ odd }}
        </td>
      </tr>
      <tr class="table-row-data">
        <td class="text-centered">{{ $t("LIVE_SHORT_FORM") }}</td>
        <td class="text-centered" v-for="odd in list.finalOdds" :key="odd">
          {{ odd }}
        </td>
      </tr>
    </template>
  </table>

  <Loading v-show="isLoading"></Loading>
</template>

<script>
const EARLY = "early";
const END = "end";
const MIDFIELD = "middle";
import { mapGetters, mapActions } from "vuex";
import MatchListDropdown from "@/components/ui/MatchListDropdown.vue";
export default {
  props: {
    matchId: {
      type: Number,
    },
  },
  components: { MatchListDropdown },
  mounted() {
    this.getLatestThreePeriodOddsData();
  },
  computed: {
    ...mapGetters(["isMobile"]),
    selectedlatest3OddsLists() {
      return this.latest3OddsLists[this.selectedFilterOption];
    },
  },
  data() {
    return {
      isLoading: false,
      isShowNoData: false,
      latest3OddsLists: {},
      selectedFilterOption: "",
      selectedFilterOptionMultiLang: "",
      oddsSelectOptions: [],
    };
  },
  methods: {
    ...mapActions(["getLatestThreePeriodOdds"]),
    async getLatestThreePeriodOddsData() {
      this.isLoading = true;
      let params = {
        matchId: this.matchId,
      };
      const result = await this.getLatestThreePeriodOdds(params);
      this.latest3OddsLists = result.result;
      let emptyResult = true;
     
      for (const [key, value] of Object.entries(this.latest3OddsLists)) {
        // set if not empty result
        if (value.length > 0) {
          value.forEach((ele) => {
            if (ele.period === EARLY) {
              ele.period = this.$t("EARLY_SHORT_FORM");
            } else if (ele.period === END) {
              ele.period = this.$t("END_SHORT_FORM");
            } else if (ele.period === MIDFIELD) {
              ele.period = this.$t("HT");
            }
          });
          emptyResult = false;
          this.oddsSelectOptions.push(key);
        }
      }

      if (this.oddsSelectOptions.length > 0) {
        this.selectedFilterOption = this.oddsSelectOptions[0];
        this.selectedFilterOptionMultiLang = this.$t(
          this.selectedFilterOption.toUpperCase()
        );
      }

      if (emptyResult) {
        this.isShowNoData = true;
      } else {
        this.isShowNoData = false;
      }

      this.isLoading = false;
    },
    updateSelectedOddList(data) {
      this.selectedFilterOption = data;
      this.selectedFilterOptionMultiLang = this.$t(
        this.selectedFilterOption.toUpperCase()
      );
    },
  },
};
</script>

<style scoped>
.header-caption {
  font-size: 0.75rem;
  margin-bottom: 0.563rem;
}
.recent-match-wrapper .dropdown {
  font-size: 0.75rem;
}
.table-content {
  width: 100%;
  font-size: 0.688rem;
}
.table-header-top-left-row {
  border-top-left-radius: 0.5rem;
}
.table-header-top-right-row {
  border-top-right-radius: 0.5rem;
}
.text-bold-grey-center {
  background: var(--color-grey-60);
  font-weight: 700;
  text-align: center;
}
.text-centered {
  text-align: center;
}
.table-content td {
  padding: 0.375rem 0;
  border-bottom: 1px solid var(--color-grey-40);
}
.table-row-data td:first-of-type,
.left-border {
  border-left: 1px solid var(--color-grey-40);
}
.table-row-data td:last-of-type {
  border-right: 1px solid var(--color-grey-40);
}
.six-column-row td {
  width: calc(100% / 6);
  box-sizing: border-box;
}
</style>
