<template>
  <div class="match-stats__wrapper" @click.prevent.stop>
    <div class="match-stats__indicator-wrapper">
      <!-- <img
        class="match-stats__indicator-img"
        src="../../../static/images/tooltips_triangle_top_bg_color.png"
      /> -->
      <div v-if="rightAdjustment !== null" class="match-stats__indicator-arrow-up" :style="{ right: rightAdjustment }"></div>
      <div v-else class="match-stats__indicator-arrow-up"></div>
    </div>
    <div class="match-stats__body">
      <div class="match-stats__column">
        <LatestThreePeriod :matchId="matchId" />
      </div>
      <div class="match-stats__column">
        <PastGoalRateTable :matchId="matchId" />
      </div>
    </div>
  </div>
</template>

<script>
import LatestThreePeriod from "@/components/match/statsTables/LatestThreePeriod.vue";
import PastGoalRateTable from "@/components/match/statsTables/PastGoalRate.vue";
export default {
  props: {
    matchId: {
      type: Number,
    },
    rightAdjustment: {
      type: String,
      default: null,
    },
  },
  components: { PastGoalRateTable, LatestThreePeriod },
};
</script>

<style scoped>
.match-stats__wrapper {
  width: 100%;
  position: absolute;
  top: 3.25rem;
  right: 0;
  z-index: 100;
}
.match-stats__indicator-wrapper {
  position: relative;
  height: 0.7rem;
}
.match-stats__indicator-img {
  position: absolute;
  right: 10.25rem;
}
.match-stats__indicator-arrow-up {
    position: absolute;
    top: 0;
    right: 10.1rem;
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.8rem solid var(--color-theme);
}
.match-stats__body {
  background: var(--color-theme);
  border-radius: 0.5rem;
  box-shadow: 1px 3px 6px 3px rgb(34 95 153);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.match-stats__column {
  width: 50%;
  box-sizing: border-box;
  padding-right: 0.5rem;
}
.match-stats__column:last-of-type {
    padding-right: 0;
}
@media (max-width: 760px) {
    .match-stats__wrapper {
        position: relative;
        top: unset;
        right: unset;
    }
    .match-stats__indicator-wrapper {
        display: none;
    }
    .match-stats__body {
        flex-direction: column;
        padding: 1.406rem 0.813rem 0.75rem;
    }
    .match-stats__column {
        width: 100%;
        padding: 1.156rem 0 0;
        padding-right: 0;
    }
    .match-stats__column:first-of-type {
        padding: 0 0;
    }
}
</style>
